.card-wrap {
    position: relative;
    z-index: 1;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: -80px;
    //     bottom: -37px;
    //     width: 293px;
    //     height: 235px;
    //     background: url("../../images/green-dots.svg");
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     z-index: 0;
    // }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     right: -50px;
    //     bottom: -40px;
    //     width: 116px;
    //     height: 176px;
    //     background: url("../../images/orange-dots.svg");
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     z-index: 0;
    // }
}