
.modal--wrap {
    .modal--overlay {
        display: block;
        position: absolute;
        background-color: rgba(0, 176, 255, 0.22);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 15px;
        backdrop-filter: blur(3px);
    }
    .modal--content {
        padding: 20px;
        background: #fff;
        border-radius: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        width: 90%;
        .modal--body {
            text-align: center;
            padding: 0px 0 20px;
            button {
                background: transparent;
            }
        }
        .modal--footer {
            display: flex;
            justify-content: center;
            align-items: center;
            .modal--confirm, .modal--cancel {
                padding: 10px 20px;
                border-radius: 12px;
                box-shadow: 0 4px 8px 0 rgba(204, 204, 204, 0.16);
                font-size: 16px;
                font-weight: 600;
                
            }
            .modal--confirm{
                background-color: #00b0ff;
                margin-right: 10px;
                color: #fff;
            }
            .modal--cancel{
                background-color: #ccc;
                color: #000;
            }
        }
    }
}