@font-face {
  font-family: "Glober";
  src: url("fonts/GloberW01-Bold.eot");
  src:
    url("fonts/GloberW01-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/GloberW01-Bold.woff") format("woff"),
    url("fonts/GloberW01-Bold.ttf") format("truetype"),
    url("fonts/GloberW01-Bold.svg#GloberW01-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Glober";
  src: url("fonts/GloberW01-SemiBold.eot");
  src:
    url("fonts/GloberW01-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("fonts/GloberW01-SemiBold.woff") format("woff"),
    url("fonts/GloberW01-SemiBold.ttf") format("truetype"),
    url("fonts/GloberW01-SemiBold.svg#GloberW01-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Glober";
  src: url("fonts/GloberW01-Book.eot");
  src:
    url("fonts/GloberW01-Book.eot?#iefix") format("embedded-opentype"),
    url("fonts/GloberW01-Book.woff") format("woff"),
    url("fonts/GloberW01-Book.ttf") format("truetype"),
    url("fonts/GloberW01-Book.svg#GloberW01-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glober";
  src: url("fonts/GloberW01-Light.eot");
  src:
    url("fonts/GloberW01-Light.eot?#iefix") format("embedded-opentype"),
    url("fonts/GloberW01-Light.woff") format("woff"),
    url("fonts/GloberW01-Light.ttf") format("truetype"),
    url("fonts/GloberW01-Light.svg#GloberW01-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GloberW01";
  src: url("fonts/GloberW01-Regular.eot");
  src:
    url("fonts/GloberW01-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/GloberW01-Regular.woff") format("woff"),
    url("fonts/GloberW01-Regular.ttf") format("truetype"),
    url("fonts/GloberW01-Regular.svg#GloberW01-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
