@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;900&display=swap");
@import "./style/styles.scss";
#root {
  background: #fff;
}
.App {
  text-align: center;
}
.practice-dyno #shell > #wideheader {
  border-bottom: none;
}
#shell > #wideheader {
  display: flex;
  position: sticky;
  top: 58px;
  background: #fff;
  border: none;
  z-index: 3;
}
.practice-dyno .submit-btn-pos #shell > #wideheader {
  top: 124px;
}
#shell > #main {
  margin-top: 0;
  min-height: auto;
}
#center > #editor {
  height: auto;
}
#center > #editor > .ProseMirror {
  height: auto;
  width: 100%;
}
.viewer #center > #editor {
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 143px);
}
#shell > #main > aside {
  margin-top: 0;
}
main #shell {
  max-height: unset;
  min-height: unset;
}
#shell > #wideheader > span.h1 {
  display: none;
}

.questionWrapper .span-container .view-answer-button {
  display: none;
}

.questionWrapper .span-container  .points-div {
  right: 0;
 }

#shell > #wideheader {
  position: relative;
  bottom: unset;
  top: unset;
  border-bottom: none;
  border-top: none;
}
#shell > #wideheader .logo-flex,
#shell > #wideheader .print-container .print-btn {
  display: none;
}
#shell > footer {
  display: none;
}

#shell {
  font-family: "Open Sans", sans-serif;
}
#shell > #wideheader > #shareprevsave > .user-info > .user-initials,
#shell > #wideheader > #shareprevsave > #savebtn,
#shell > #wideheader > #shareprevsave > .user-info > .user-initials,
#shell > #wideheader > #shareprevsave > .submitbtn,
#shell > #wideheader > #shareprevsave > .points-container {
  display: none;
}

#shell .report-wrap {
  z-index: 3;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.blinking-button {
  background: #1a73e8; /* blue color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: pulse1 2s infinite;
}
.disable-blinking-button {
  background: #c0cad4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
  font-size: 16px;
  &:hover {
    background: #c0cad4;
  }
}
.confettti-box {
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 0;
}

@keyframes pulse1 {
  0% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(26, 115, 232, 0.5);
  }
  50% {
      transform: scale(1.05);
      box-shadow: 0 0 20px rgba(26, 115, 232, 0.8);
  }
  100% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(26, 115, 232, 0.5);
  }
}
.report-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.report-card {
  background: linear-gradient(135deg, #ffecd2, rgba(131, 90, 238,0.3), #ffdde1, #c1e1ff, #d4fc79);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: rgba(10, 94, 255, 0.12) 0px 1px 2px 0px, rgba(10, 94, 255, 0.12) 0px 2px 6px 2px;
  text-align: center;
  max-width: 700px;
  width: 100%;
  background-color: #fff;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-text {
  font-size: 32px;
  font-family: "OpenSans", "Open Sans";
  font-weight: 600;
}
.dyno-loader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 200px;
  height: 100px;
}
.shape {
  opacity: 0;
  animation: appear 1.5s infinite;
}

.box {
  width: 50px;
  height: 50px;
  background-color: #ff637ad9;
  animation-delay: 0s;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 50px solid #29b473c1;
  animation-delay: 0.4s;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: #845aeecd;
  border-radius: 50%;
  animation-delay: 0.8s;
}

@keyframes appear {
  0%, 20% {
      opacity: 0;
      transform: scale(0);
  }
  40%, 60% {
      opacity: 1;
      transform: scale(1);
  }
  80%, 100% {
      opacity: 0;
      transform: scale(0);
  }
}
#shell > #wideheader > #submitbtn {
  display: block;
  border-radius: 21.5px;
  background-color: #1b6ec2;
  font-family: "Glober";
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 auto;
  padding: 10px 35px;
  border: solid 1px transparent;
  transition-duration: 0.4s;
  text-transform: uppercase;
  line-height: unset;
  cursor: pointer;
}
.review-page #shell > #wideheader > #submitbtn {
  display: none;
}
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(238, 238, 238, 0.7);
}
.practice-dyno,
.common-dyno {
  width: calc(100% - 450px);
  background-color: #f7fafb;
}
.info-container {
  display: flex;
  justify-content: center;
  font-family: "Glober";
  font-weight: 600;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.chat {
  height: calc(100vh - 138px);
  margin: 30px 0 0;
}
.small-screen {
  width: 450px;
  position: fixed;
  bottom: 10px;
  padding-right: 20px;
}
.close-chat {
  position: fixed;
  bottom: 10px;
  height: unset;
  width: 450px;
  padding-right: 20px;
}
.timer-chat-box {
  width: 450px;
  background: #f7fafb;
  padding-right: 20px;
}
.timer-chat-wrap {
  display: block; 
  position: sticky; 
  top: 132px; 
  padding: 30px 0 10px;
  width: 100%;
}
.info-wrapper {
  height: 100%;
  border-radius: 15px;
  box-shadow: 4px 16px 24px 0 rgba(10, 94, 255, 0.12);
  background: #fff;
  border: 1px solid rgba(0, 176, 255, 0.6);
  width: 100%;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.padding-40 {
  padding: 40px;
}
.padding-10 {
  padding: 10px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
#main > #document.viewer, #shell > #wideheader {
  background-color: #f7fafb;
}
.time-elapsed,
.time-taken {
  font-size: 18px;
  color: #00b0ff;
}
.timer-container {
  border-radius: 50%;
  border: 1px solid #00b0ff;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer-text {
  font-weight: 600;
  color: #d46d1b;
  font-size: 20px;
}
.background-image {
  background: url("../src/images/bg.png");
}

@media only screen and (max-width: 1024px) {
  .practice-dyno,
  .common-dyno {
    width: 100%;
  }
  .quiz-page,
  .review-page {
    flex-direction: column-reverse;
  }
  .timer-chat-box {
    width: 100%;
    background: #f7fafb;
    padding-right: unset;
  }
  .timer-chat-wrap {
    padding: 0;
    z-index: 4;
  }
  .info-container {
    padding: 0px 10px;
    width: 100%;
    // height: unset;
  }
  .info-wrapper {
    border-radius: unset;
    box-shadow: none;
    background: transparent;
    border: unset;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .small-screen {
    position: fixed;
    bottom: 10px;
    &.info-container {
      justify-content: flex-end;
    }
    .info-wrapper {
      background: #fff;
      width: 60%;
      border-radius: 20px;
      box-shadow: rgba(0, 176, 255, 0.4) 0px 5px 15px;
      .chat-container {
        width: 100%;
      }
    }
  }
  .time-elapsed,
  .time-taken {
    font-size: 16px;
  }
  .timer-container {
    border-radius: unset;
    border: unset;
    width: unset;
    height: unset;
    display: block;
  }
  .timer-text {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .report-card {
    padding: 2rem;
  }
  .small-screen {
    .info-wrapper {
      width: 100%;
    }
  }
}