$border-color: #e0e0e0;
$background-light: #f8f8f8;
$background-dark: #fafafa;
$text-color: #888;
$primary-color: #6200ea;
$primary-hover: #3700b3;
$profile-pic-color: #ccc;

.chat-container {
  height: 100%;
  position: relative;
  .chat-header, .chat-header-close {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #c4e4f6;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    justify-content: space-between;

    .profile-pic {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .chat-info {
      flex-grow: 1;
    }
    .chat-disclaimer {
      font-size: 11px;
      font-family: "open sans";
      font-weight: 400;
    }
  }
  .chat-header-close {
    border-radius: 15px;
  }

  .chat-body {
    padding: 10px;
    height: calc(100% - 61px);
    overflow: scroll;

    .message {
      margin: 5px 0;
      padding: 10px;
      border-radius: 20px;
      max-width: 80%;
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 400;

      &.sent {
        background: rgba(131, 90, 238, 0.2);
      }

      &.received {
        background: rgba(46, 207, 227, 0.2);
        ol, ul {
          padding: 5px 0 5px 16px;
        }
        p {
          line-height: 1.8;
        }
        li {
          line-height: 2;
        }
      }
    }

    .loading-indicator {
      text-align: center;
      color: $text-color;
    }
  }

  .chat-input-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    position: sticky;
    bottom: 10px;
    

    .chat-input, .chat-disable {
      display: inline-block;
      border: 1px solid hsla(0, 0%, 46.3%, .8);
      border-radius: 20px;
      padding: 5px;
      cursor: pointer;
      margin: 5px 5px 0;
      color: #000;
      position: relative;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 400;
      font-family: "open sans";
      input {
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        border: none;
        &.question-disable {
          cursor: not-allowed;
        }
      }
    }
    .chat-input {
      &:hover {
        background: rgba(0, 176, 255,0.05);
        border-color: #00b0ff;
      }
    }
    .chat-disable {
        background: rgba(204, 204, 204,0.2);
      &:hover {
        background: rgba(204, 204, 204,0.2);
      }
    }

    .send-btn {
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 50%;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;

      &:hover {
        transform: translate(5px, 0);
        transition: all 500ms linear;
      }
    }
  }
}
