main {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  .button-container {
    position: sticky;
    top: 0;
    background: rgb(250, 250, 250);
    z-index: 3;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
  #main {
    margin: 0;
  }
  header#wideheader {
    display: none;
  }
  #titlebar {
    display: none;
  }
  .position {
    top: 58px;
  }
}
